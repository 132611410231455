import { render, staticRenderFns } from "./PopupDialog.vue?vue&type=template&id=20eb0bcc&scoped=true&"
import script from "./PopupDialog.vue?vue&type=script&lang=js&"
export * from "./PopupDialog.vue?vue&type=script&lang=js&"
import style0 from "./PopupDialog.vue?vue&type=style&index=0&id=20eb0bcc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20eb0bcc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20eb0bcc')) {
      api.createRecord('20eb0bcc', component.options)
    } else {
      api.reload('20eb0bcc', component.options)
    }
    module.hot.accept("./PopupDialog.vue?vue&type=template&id=20eb0bcc&scoped=true&", function () {
      api.rerender('20eb0bcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8OJ8XAyY4IcLy/JS/comp/PopupDialog.vue"
export default component.exports